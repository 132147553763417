import * as React from "react"
import { Link } from "gatsby"

import '../styles/home.css'
import '../styles/404.css'
import '../styles/skeleton.css'
import '../styles/normalize.css'
import DarkModeToggle from "../components/DarkModeToggle"
import Footer from "../components/Footer"

// markup
const NotFoundPage = () => {
  return (
    <main>
      <div class="main-404"></div>
      <title>Not found</title>
      <div class="row content row-404">
        <h1 class="h1-404 twelve columns">Oopsies Daisies</h1>
        <div className="intro">
          <p class="p-404">
            I think you got lost.
            <br />
            <br />
          </p>
          <Link to="/"><button class="home-button">GO BACK</button></Link>
        </div>
      </div>
    </main>
  )
}

export default NotFoundPage
